<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12" v-if="pageLoaded">
      <v-card-title style="background-color: transparent !important">
        <h3>گزارش خدمات شیفت</h3>
      </v-card-title>
      <v-tabs
        class="mt-1"
        background-color="transparent"
        vertical
        id="tabs"
        v-model="tab"
        :style="
          deviceType == 'mobile'
            ? 'border-bottom : 1px solid rgba(0, 0, 0, 0.1) !important'
            : ''
        "
      >
        <v-tab
          class="tab-title"
          :class="role == 'dentistryNurse' ? 'last-tab-item-br' : ''"
          :style="role == 'dentistryNurse' ? '' : 'border-radius: 0 !important'"
        >
          کلی
        </v-tab>
        <v-tab v-if="role == 'admin'" class="tab-title last-tab-item-br">
          گزارش پایان شیفت
        </v-tab>

        <v-tabs-items v-model="tab" id="shiftReport" ref="docReport">
          <!-- کلی -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 500px">
              <v-row class="px-2 pt-2">
                <v-col>
                  <h5 class="common-text">
                    <span class="primary--text font-weight-bold"
                      >عنوان شیفت:</span
                    >
                    {{ shiftInfo.subject }}
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    از: <span dir="ltr">{{ shiftInfo.startDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.startDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.startTime
                    }}</span>
                  </h5>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    تا: <span dir="ltr">{{ shiftInfo.endDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.endDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.endTime
                    }}</span>
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col
                  v-if="shiftInfo.doctorName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.doctorName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold"
                      >دندانپزشک:</span
                    >
                    دکتر {{ shiftInfo.doctorName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold"
                      >دندانپزشکان:</span
                    >
                    <span
                      v-for="doc in shiftInfo.doctorName"
                      :key="doc.index"
                      style="line-height: 30px"
                    >
                      - دکتر {{ doc }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.nurseName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.nurseName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold"
                      >دستیار دندانپزشک:</span
                    >
                    {{ shiftInfo.nurseName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold"
                      >دستیاران دندانپزشک:</span
                    >

                    <span
                      v-for="nurse in shiftInfo.nurseName"
                      :key="nurse.index"
                      style="line-height: 30px"
                    >
                      - {{ nurse }}</span
                    >
                  </h5>
                </v-col>
                <!-- <v-col
                  v-if="shiftInfo.receptionName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.receptionName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پذیرش:</span>
                    {{ shiftInfo.receptionName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">
                      کارمندان پذیرش:
                    </span>
                    <span
                      v-for="reception in shiftInfo.receptionName"
                      :key="reception.index"
                      style="line-height: 30px"
                    >
                      - {{ reception }}</span
                    >
                  </h5>
                </v-col> -->
              </v-row>
              <hr v-show="!isPrinting" />
              <v-card class="pa-3">
                <v-card-title>
                  <v-row>
                    <v-col
                      ><div>
                        <v-btn
                          class="secondary-btn dontPrint"
                          @click="printShiftReport"
                          :disabled="computedDisabled"
                          v-show="!isPrinting"
                          >پرینت گزارش خدمات شیفت</v-btn
                        >
                      </div></v-col
                    >
                    <v-col>
                      <v-btn
                        @click="$refs.shiftReportExcel.$el.click()"
                        style="float: left"
                        v-show="!isPrinting"
                        class="secondary-btn dontPrint"
                        :disabled="computedDisabled"
                        v-if="role != 'dentistryNurse'"
                        >دریافت اکسل خدمات شیفت</v-btn
                      >
                      <vue-excel-xlsx
                        v-show="false"
                        ref="shiftReportExcel"
                        :data="excelDocItems"
                        :columns="
                          excelDocFields.map((x) => {
                            return {
                              label: x.label,
                              field: x.key,
                            };
                          })
                        "
                        :filename="'گزارش شیفت'"
                        :sheetname="currentDate"
                      >
                        دریافت اکسل
                      </vue-excel-xlsx>
                    </v-col>
                  </v-row>
                </v-card-title>
                <hr class="mb-5" v-if="role == 'admin'" v-show="!isPrinting" />
                <div class="break-avoid" v-if="role == 'admin'">
                  <div v-if="shiftInfo.personel.length">
                    <h5>
                      <strong>ساعات ورود و خروج</strong>
                    </h5>
                    <b-table
                      responsive
                      show-empty
                      :fields="inOutFields"
                      :items="shiftInfo.personel"
                      empty-text="رکوردی برای نمایش وجود ندارد"
                      empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.value }}
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.index + 1 }}
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
                <hr class="mb-4" />

                <v-row v-if="!isPrinting" class="break-avoid my-2 px-4">
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <div
                      v-if="shiftInfo.totalCost != ''"
                      class="sum-div px-10 py-5"
                    >
                      <div
                        class="text-center mt-2 d-flex justify-content-center"
                      >
                        <div style="cursor: pointer" @click="infoDialog = true">
                          <v-icon class="pe-2" color="dark"
                            >mdi-information-outline</v-icon
                          >
                        </div>

                        <span
                          class="font-weight-bold primary--text"
                          :class="isPrinting ? 'h6' : 'h2'"
                        >
                          کل کارکرد:
                        </span>
                        <span
                          class="primary--text font-weight-bold"
                          :class="isPrinting ? 'h6' : 'h2'"
                        >
                          {{ Number(shiftInfo.totalIncome).toLocaleString() }}
                          ریال
                        </span>
                      </div>
                      <v-row class="mt-3">
                        <v-col>
                          <hr v-if="isPrinting" />
                          <div class="d-flex flex-column justify-space-between">
                            <div class="mt-3">
                              <span
                                class="font-weight-bold"
                                :class="isPrinting ? 'h6' : 'h4'"
                              >
                                کارکرد دندانپزشکی
                              </span>

                              <span
                                class="float-end"
                                :class="isPrinting ? 'h6' : 'h4'"
                              >
                                {{
                                  Number(
                                    shiftInfo.dentistryTotalTotal
                                  ).toLocaleString()
                                }}
                                ریال
                              </span>
                            </div>
                          </div></v-col
                        >
                      </v-row>
                      <!--<div
                        class="mt-4 text-center mb-3"
                        v-if="role != 'reception'"
                      >
                        <span
                          :class="isPrinting ? 'h6' : 'h3'"
                          class="font-weight-bold primary--text"
                        >
                          کارکرد بدون اقلام حفاظتی:
                        </span>
                        <span
                          class="font-weight-bold primary--text"
                          :class="isPrinting ? 'h6' : 'h3'"
                        >
                          {{ Number(shiftInfo.totalCost).toLocaleString() }}
                          ریال
                        </span>
                      </div>-->

                      <!-- <div class="mt-3">
                        <span
                          class="font-weight-bold"
                          :class="isPrinting ? 'h6' : 'h3'"
                        >
                          جمع دریافتی:
                        </span>
                        <span
                          class="float-end h2"
                          style="width: 35%"
                          :class="isPrinting ? 'h6' : 'h3'"
                          >{{ Number(shiftInfo.totalIncome).toLocaleString() }}
                          ریال
                        </span>
                      </div>-->
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                    <div class="sum-div px-10 py-5">
                      <v-row>
                        <v-col class="pb-0">
                          <div
                            :class="!isPrinting ? 'justify-content-center' : ''"
                            class="mt-1 d-flex"
                          >
                            <div
                              style="cursor: pointer"
                              @click="infoDialog = true"
                            >
                              <v-icon class="pe-2" color="dark"
                                >mdi-information-outline</v-icon
                              >
                            </div>
                            <span
                              class="font-weight-bold font-wight-bold primary--text"
                              :class="isPrinting ? 'h6' : 'h2'"
                            >
                              مجموع درآمد:
                            </span>

                            <span
                              class="font-weight-bold primary--text"
                              :class="isPrinting ? 'h6' : 'h2'"
                              >{{
                                Number(
                                  shiftInfo.totalFinanceIncome
                                ).toLocaleString()
                              }}
                              ریال
                            </span>
                          </div></v-col
                        >
                        <v-col class="pb-0">
                          <div
                            class="mt-1"
                            :class="!isPrinting ? 'text-center' : ''"
                            v-if="role == 'admin'"
                          >
                            <span
                              class="font-weight-bold font-wight-bold primary--text"
                              :class="isPrinting ? 'h6' : 'h2'"
                            >
                              سهم واحد دندانپزشکی:
                            </span>

                            <span
                              class="font-weight-bold primary--text"
                              :class="isPrinting ? 'h6' : 'h2'"
                              >{{
                                Number(shiftInfo.doctorShare).toLocaleString()
                              }}
                              ریال
                            </span>
                          </div></v-col
                        >
                      </v-row>
                      <hr v-if="isPrinting" />
                      <v-row class="mt-10 pb-4">
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          lg="6"
                          class="pe-5 pt-0"
                        >
                          <div class="d-flex flex-column justify-space-between">
                            <div class="mt-3">
                              <span
                                class="font-weight-bold"
                                :class="isPrinting ? 'h6' : 'h4'"
                              >
                                کارتخوان
                              </span>
                              <span
                                class="float-end"
                                :class="isPrinting ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.pos != "-"
                                    ? Number(shiftInfo.pos).toLocaleString()
                                    : "-"
                                }}
                                ریال
                              </span>
                            </div>

                            <div class="mt-4">
                              <span
                                class="font-weight-bold"
                                :class="isPrinting ? 'h6' : 'h4'"
                              >
                                نقدی
                              </span>

                              <span
                                class="float-end"
                                :class="isPrinting ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.cash != "-"
                                    ? Number(shiftInfo.cash).toLocaleString()
                                    : "-"
                                }}
                                ریال
                              </span>
                            </div>

                            <div class="mt-4">
                              <span
                                class="font-weight-bold"
                                :class="isPrinting ? 'h6' : 'h4'"
                              >
                                چک
                              </span>

                              <span
                                class="float-end"
                                :class="isPrinting ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.cheque != "-"
                                    ? Number(shiftInfo.cheque).toLocaleString()
                                    : "-"
                                }}
                                ریال
                              </span>
                            </div>

                            <div class="mt-4">
                              <span
                                class="font-weight-bold"
                                :class="isPrinting ? 'h6' : 'h4'"
                              >
                                کارت به کارت
                              </span>

                              <span
                                class="float-end"
                                :class="isPrinting ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.card != "-"
                                    ? Number(shiftInfo.card).toLocaleString()
                                    : "-"
                                }}
                                ریال
                              </span>
                            </div>
                          </div>
                        </v-col>

                        <v-divider
                          vertical
                          class="d-none d-lg-block"
                          style="height: 100%"
                        ></v-divider>

                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          lg="6"
                          class="ps-5 pt-0"
                        >
                          <v-divider class="d-block d-lg-none pb-4"></v-divider>
                          <div class="d-flex flex-column justify-space-between">
                            <div class="mt-4">
                              <span
                                class="font-weight-bold"
                                :class="isPrinting ? 'h6' : 'h4'"
                              >
                                درآمد ناشی از دندانپزشکی
                              </span>

                              <span
                                class="float-end"
                                :class="isPrinting ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.dentisteryIncome != "-"
                                    ? Number(
                                        shiftInfo.dentisteryIncome
                                      ).toLocaleString()
                                    : "-"
                                }}

                                ریال
                              </span>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>

                <v-row v-else-if="isPrinting">
                  <v-col cols="4">
                    <div
                      v-if="shiftInfo.totalCost != ''"
                      class="sum-div px-10 py-5"
                    >
                      <div
                        class="text-center mt-2 d-flex justify-content-center"
                      >
                        <span class="font-weight-bold primary--text h5">
                          کل کارکرد:
                        </span>

                        <span class="primary--text font-weight-bold h5">
                          {{ Number(shiftInfo.totalIncome).toLocaleString() }}
                          ریال
                        </span>
                      </div>
                      <hr style="border-top: 2px solid grey" />
                      <div class="d-flex flex-column justify-space-between">
                        <div class="mt-3">
                          <span class="font-weight-bold h6">
                            کارکرد دندانپزشکی
                          </span>

                          <span class="h6" style="float: left">
                            {{
                              Number(
                                shiftInfo.dentistryTotalTotal
                              ).toLocaleString()
                            }}
                            ریال
                          </span>
                        </div>
                      </div>
                      <!--<div
                        class="mt-4 text-center mb-3"
                        v-if="role != 'reception'"
                      >
                        <span
                          :class="isPrinting ? 'h6' : 'h3'"
                          class="font-weight-bold primary--text"
                        >
                          کارکرد بدون اقلام حفاظتی:
                        </span>
                        <span
                          class="font-weight-bold primary--text"
                          :class="isPrinting ? 'h6' : 'h3'"
                        >
                          {{ Number(shiftInfo.totalCost).toLocaleString() }}
                          ریال
                        </span>
                      </div>-->

                      <!-- <div class="mt-3">
                        <span
                          class="font-weight-bold"
                          :class="isPrinting ? 'h6' : 'h3'"
                        >
                          جمع دریافتی:
                        </span>
                        <span
                          class="float-end h2"
                          style="width: 35%"
                          :class="isPrinting ? 'h6' : 'h3'"
                          >{{ Number(shiftInfo.totalIncome).toLocaleString() }}
                          ریال
                        </span>
                      </div>-->
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="sum-div px-10 py-5">
                      <div
                        class="text-center mt-2 d-flex justify-content-center"
                      >
                        <span class="font-weight-bold primary--text h5">
                          مجموع درآمد:
                        </span>

                        <span class="primary--text font-weight-bold h5">
                          {{
                            Number(
                              shiftInfo.totalFinanceIncome
                            ).toLocaleString()
                          }}
                          ریال
                        </span>
                      </div>
                      <hr style="border-top: 2px solid grey" />
                      <div class="d-flex flex-column justify-space-between">
                        <div class="mt-3">
                          <span class="font-weight-bold h6"> کارتخوان </span>
                          <span class="float-end h6" style="float: left" h6>
                            {{
                              shiftInfo.pos != "-"
                                ? Number(shiftInfo.pos).toLocaleString()
                                : "-"
                            }}
                            ریال
                          </span>
                        </div>

                        <div class="mt-4">
                          <span class="font-weight-bold h6"> نقدی </span>

                          <span class="float-end h6" style="float: left">
                            {{
                              shiftInfo.cash != "-"
                                ? Number(shiftInfo.cash).toLocaleString()
                                : "-"
                            }}
                            ریال
                          </span>
                        </div>

                        <div class="mt-4">
                          <span class="font-weight-bold h6"> چک </span>

                          <span class="float-end h6" style="float: left">
                            {{
                              shiftInfo.cheque != "-"
                                ? Number(shiftInfo.cheque).toLocaleString()
                                : "-"
                            }}
                            ریال
                          </span>
                        </div>

                        <div class="mt-4">
                          <span class="font-weight-bold h6">
                            کارت به کارت
                          </span>

                          <span class="float-end h6" style="float: left">
                            {{
                              shiftInfo.card != "-"
                                ? Number(shiftInfo.card).toLocaleString()
                                : "-"
                            }}
                            ریال
                          </span>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="sum-div px-10 py-5">
                      <div
                        class="text-center mt-2 d-flex justify-content-center"
                        v-if="role == 'admin'"
                      >
                        <span class="font-weight-bold primary--text h5">
                          سهم واحد دندانپزشکی:
                        </span>

                        <span class="primary--text font-weight-bold h5">
                          {{ Number(shiftInfo.doctorShare).toLocaleString() }}
                          ریال
                        </span>
                      </div>
                      <hr
                        style="border-top: 2px solid grey"
                        v-if="role == 'admin'"
                      />
                      <div
                        class="d-flex flex-column justify-space-between"
                        :style="role == 'admin' ? '' : 'margin-top:71px'"
                      >
                        <div class="mt-4">
                          <span class="font-weight-bold h6">
                            درآمد ناشی از دندانپزشکی
                          </span>

                          <span class="h6" style="float: left">
                            {{
                              shiftInfo.dentisteryIncome != "-"
                                ? Number(
                                    shiftInfo.dentisteryIncome
                                  ).toLocaleString()
                                : "-"
                            }}

                            ریال
                          </span>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-dialog v-model="infoDialog" width="600">
                  <v-card class="px-3 py-5" style="border-radius: 20px">
                    <v-container>
                      <v-row class="px-2">
                        <v-col class="text-right">
                          <h4 class="cyan--text text--darken-4">راهنما</h4>
                        </v-col>
                        <v-col class="text-left">
                          <span
                            @click="infoDialog = false"
                            style="cursor: pointer"
                          >
                            <v-icon color="red">mdi-close</v-icon>
                          </span>
                        </v-col>
                      </v-row>
                      <v-card-text
                        class="text-justify blue-grey--text darken-4"
                      >
                        <h5>همکار گرامی،</h5>
                        <ul class="ps-5 mt-2">
                          <li class="h3" style="line-height: 30px">
                            داده‌های مربوط به کارکرد از عملکرد و مراجعات واقعی
                            بیماران به کلینیک استخراج شده است.
                          </li>
                          <li class="h3" style="line-heighst: 30px">
                            داده‌های مربوط به درآمد، از وجوه پرداختی استخراج شده
                            است.
                          </li>
                          <li class="h3" style="line-height: 30px">
                            در خصوص خدماتی که پرداخت آنها جزئی است، این داده‌ها
                            با یکدیگر تفاوت خواهند داشت.
                          </li>
                          <li class="h3" style="line-height: 30px">
                            در صورتی که کلیه خدمات ارائه شده در این شیفت، در
                            همین شیفت پرداخت شده باشند، این داده‌ها باید با
                            یکدیگر همخوانی و سازگاری داشته باشند.
                          </li>
                        </ul>
                      </v-card-text>
                    </v-container>
                  </v-card>
                </v-dialog>
                <div class="break-avoid" v-if="shiftInfo.dentistry.length">
                  <hr />
                  <h5>
                    <strong>دندانپزشکی</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.dentistryTotalCount
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.dentistryTotalTotal
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.dentistryTotalClinic
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.dentistryTotalCosts
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.dentistry"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <v-row class="my-4 pa-2" v-show="isPrinting">
                  <v-col cols="6">
                    <div
                      class="px-3 py-4 rounded-xl"
                      style="height: 200px; border: 2px solid #00a7b7"
                    >
                      <span class="font-weight-bold h4">
                        محل امضای پذیرش:
                      </span>
                    </div>
                  </v-col>
                  <!--<v-col cols="6">
                    <div
                      v-if="
                        shiftInfo.doctorName.length == 1 && role == 'reception'
                      "
                      class="px-3 py-4 rounded-xl"
                      style="height: 200px; border: 2px solid #00a7b7"
                    >
                      <span class="font-weight-bold h4"> محل امضای پزشک: </span>
                    </div>
                  </v-col>-->
                </v-row>
              </v-card>
            </v-card>
          </v-tab-item>
          <!-- گزارش پایان شیفت -->
          <v-tab-item v-if="role == 'admin'">
            <v-card class="pa-5">
              <br />
              <h4>
                <v-row>
                  <v-col
                    >گزارش شیفت {{ report.shift }} -
                    {{
                      weekDict[moment(report.date, "jYYYY/jMM/jDD").weekday()]
                    }}
                    - تاریخ {{ report.date }}</v-col
                  >
                  <v-col
                    >از ساعت {{ report.shiftStart }} تا
                    {{ report.shiftEnd }}</v-col
                  >
                </v-row>
              </h4>
              <br />
              <hr />
              <h4 class="primary--text text-center mb-3">
                کادر درمان و پرسنل حاضر در شیفت
              </h4>
              <v-row>
                <v-col v-if="report.lastShiftDoc">
                  <p v-if="report.lastShiftDoc.length == 1">
                    پزشک: دکتر {{ report.lastShiftDoc[0] }}
                  </p>
                  <p v-else>
                    پزشکان:
                    <span
                      v-for="doc in report.lastShiftDoc"
                      :key="doc.index"
                      style="line-height: 30px"
                    >
                      - دکتر {{ doc }}</span
                    >
                  </p>
                </v-col>
                <v-col v-if="report.lastShiftNurse">
                  <p v-if="report.lastShiftNurse.length == 1">
                    دستیار دندانپزشک: {{ report.lastShiftNurse[0] }}
                  </p>
                  <p v-else>
                    دستیاران دندانپزشک:
                    <span
                      v-for="nurse in report.lastShiftNurse"
                      :key="nurse.index"
                      style="line-height: 30px"
                    >
                      - {{ nurse }}</span
                    >
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="report.lastShiftDentist">
                  <p v-if="report.lastShiftDentist.length == 1">
                    دندانپزشک: دکتر {{ report.lastShiftDentist[0] }}
                  </p>
                  <p v-else>
                    دندانپزشکان:
                    <span
                      v-for="doc in report.lastShiftDentist"
                      :key="doc.index"
                      style="line-height: 30px"
                    >
                      - دکتر {{ doc }}</span
                    >
                  </p>
                </v-col>
                <v-col v-if="report.lastShiftNurseDentist">
                  <p v-if="report.lastShiftNurseDentist.length == 1">
                    دستیار دندانپزشک:
                    {{ report.lastShiftNurseDentist[0] }}
                  </p>
                  <p v-else>
                    دستیاران دندانپزشک:
                    <span
                      v-for="nurse in report.lastShiftNurseDentist"
                      :key="nurse.index"
                      style="line-height: 30px"
                    >
                      - {{ nurse }}</span
                    >
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-col>
                  <span class="common-text">
                    کارمند پذیرش: {{ report.reception }}
                  </span>
                </v-col> -->
                <v-col v-if="report.lastShiftServant">
                  <p v-if="report.lastShiftServant.length == 1">
                    پرسنل خدمات: {{ report.lastShiftServant[0] }}
                  </p>
                  <p v-else>
                    پرسنل خدمات:
                    <span
                      v-for="servant in report.lastShiftServant"
                      :key="servant.index"
                      style="line-height: 30px"
                    >
                      - {{ servant }}</span
                    >
                  </p>
                </v-col>
              </v-row>
              <br />
              <hr />
              <h4 class="primary--text text-center mb-3">خلاصه کارکرد مالی</h4>
              <v-row>
                <v-col class="common-text">
                  دریافتی کارتخوان:
                  {{
                    shiftInfo.reportCard
                      ? Number(shiftInfo.reportCard).toLocaleString()
                      : 0
                  }}
                  ﷼
                </v-col>
                <v-col class="common-text">
                  دریافتی نقدی:
                  {{
                    shiftInfo.reportCash
                      ? Number(shiftInfo.reportCash).toLocaleString()
                      : 0
                  }}
                  ﷼
                </v-col>
              </v-row>
              <v-row>
                <v-col class="common-text">
                  مبلغ کسری صندوق:
                  {{ report.lack ? Number(report.lack).toLocaleString() : 0 }}
                  ﷼
                </v-col>
                <v-col class="common-text">
                  مبلغ اضافه صندوق:
                  {{ report.extra ? Number(report.extra).toLocaleString() : 0 }}
                  ﷼
                </v-col>
              </v-row>
              <v-row>
                <v-col class="common-text">
                  طلب از بیمار:
                  {{ report.talab ? Number(report.talab).toLocaleString() : 0 }}
                  ﷼
                </v-col>
                <v-col class="common-text">
                  بدهی به بیمار:
                  {{ report.debt ? Number(report.debt).toLocaleString() : 0 }}
                  ﷼
                </v-col>
              </v-row>
              <v-row>
                <v-col class="common-text">
                  توضیحات صندوق:
                  {{ report.cashDescription ? report.cashDescription : "-" }}
                </v-col>
              </v-row>
              <hr />
              <h4 class="primary--text text-center mb-3">خلاصه اتفاقات شیفت</h4>
              <p style="line-height: 22px">
                1. آیا کمبودی در وسایل و امکانات و تجهیزات در شیفت وجود داشت؟
                <b>{{
                  report.isFacilitiesShortage == "yes" ? "بله" : "خیر"
                }}</b>
              </p>
              <p
                v-if="report.isFacilitiesShortage == 'yes'"
                style="line-height: 22px"
              >
                توضیحات و شرح کمبود: {{ report.facilitiesShortage }}
              </p>
              <p style="line-height: 22px">
                آیا در طول مدت شیفت بیمار یا مراجعه کننده ای اعتراض /ناراحتی/
                شکایت یا درگیری در خصوص نحوه ارایه خدمات درمانگاه دندانپزشکی و
                یا پزشک درمانگاه داشت؟
                <b>{{ report.isDissatisfaction == "yes" ? "بله" : "خیر" }}</b>
              </p>
              <p
                v-if="report.isDissatisfaction == 'yes'"
                style="line-height: 22px"
              >
                توضیحات و شرح نارضایتی: {{ report.dissatisfaction }}
              </p>
              <div>
                <h6 style="line-height: 22px">سایر موارد:</h6>
                <p
                  v-if="report.others"
                  class="c-grey fs-medium text-justify"
                  v-html="report.others"
                ></p>
                <span v-else>-</span>
              </div>
              <div>
                <h6 style="line-height: 22px">
                  پیشنهاد جهت ارتقاء فرآیند کاری درمانگاه:
                </h6>
                <p
                  v-if="report.suggestions"
                  class="c-grey fs-medium text-justify"
                  v-html="report.suggestions"
                ></p>
                <span v-else>-</span>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </div>
</template>

<script>
import moment from "moment-jalaali";

export default {
  data() {
    return {
      deviceType: "",
      tab: null,
      innerTab: null,
      pageLoaded: false,
      shiftInfo: {},
      report: {},
      excelDocItems: [],
      moment: moment,
      weekDict: [
        "شنبه",
        "یکشنبه",
        "دوشنبه",
        "سه شنبه",
        "چهارشنبه",
        "پنج شنبه",
        "جمعه",
      ],
      Busy: false,
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      role: "",
      isPrinting: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "خدمت" },
        { key: "count", label: "تعداد" },
        { key: "total", label: "کارکرد درمانگاه" },
        // { key: "totalExpens", label: "هزینه مواد مصرفی" },
        { key: "totalCost", label: "مبلغ دریافتی" },
      ],
      excelDocFields: [
        { key: "name", label: "خدمت" },
        { key: "count", label: "تعداد" },
        { key: "total", label: "کارکرد درمانگاه" },
        // { key: "totalExpens", label: "هزینه مواد مصرفی" },
        { key: "totalCost", label: "مبلغ دریافتی" },
      ],
      infoDialog: false,
      inOutFields: [
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "role", label: "نقش" },
        { key: "start", label: "آغاز" },
        { key: "end", label: "پایان" },
      ],
    };
  },

  methods: {
    getShiftInfo() {
      //NOTE getting all shift data from server when page mount
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/dentistry/reports/shift",
          {
            shiftId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.shiftInfo = res.data;
            this.shiftInfo.generalTotal = [];
            let generalTotalTemp = {};
            generalTotalTemp.generalTotalCount =
              this.shiftInfo.visitTotalCount +
              this.shiftInfo.baliniTotalCount +
              this.shiftInfo.baliniOutTotalCount +
              this.shiftInfo.surgeryTotalCount +
              this.shiftInfo.surgeryOutTotalCount;
            generalTotalTemp.generalTotalTotal =
              this.shiftInfo.visitTotalTotal +
              this.shiftInfo.baliniTotalTotal +
              this.shiftInfo.baliniOutTotalTotal +
              this.shiftInfo.surgeryTotalTotal +
              this.shiftInfo.surgeryOutTotalTotal;
            generalTotalTemp.generalTotalClinic =
              this.shiftInfo.visitTotalClinic +
              this.shiftInfo.baliniTotalClinic +
              this.shiftInfo.baliniOutTotalClinic +
              this.shiftInfo.surgeryTotalClinic +
              this.shiftInfo.surgeryOutTotalClinic;
            generalTotalTemp.generalTotalExpenses =
              this.shiftInfo.visitTotalExpenses +
              this.shiftInfo.baliniTotalExpenses +
              this.shiftInfo.baliniOutTotalExpenses +
              this.shiftInfo.surgeryTotalExpenses +
              this.shiftInfo.surgeryOutTotalExpenses;
            generalTotalTemp.generalTotalCosts =
              this.shiftInfo.visitTotalCosts +
              this.shiftInfo.baliniTotalCosts +
              this.shiftInfo.baliniOutTotalCosts +
              this.shiftInfo.surgeryTotalCosts +
              this.shiftInfo.surgeryOutTotalCosts;
            this.shiftInfo.generalTotal[0] = generalTotalTemp;
            this.pageLoaded = true;
            this.excelDocItems = this.shiftInfo.visits.concat(
              this.shiftInfo.takhasos,
              this.shiftInfo.takhasosClinical,
              this.shiftInfo.homeCare,
              this.shiftInfo.balinis,
              this.shiftInfo.outBalinis,
              this.shiftInfo.surgery,
              this.shiftInfo.surgeryOut,
              this.shiftInfo.others,
              this.shiftInfo.dentistry,
              this.shiftInfo.laboratory
            );
            try {
              this.report = JSON.parse(this.shiftInfo.report);
            } catch (error) {
              this.report = {};
            }

            this.vLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.vLoading = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    printShiftReport() {
      this.isPrinting = true;
      // Use nextTick to trigger the print on next update
      this.$nextTick(() => {
        this.$htmlToPaper("shiftReport", null, () => {
          this.isPrinting = false; // hide the <p> tag when isPrinting is done
        });
      });
    },
  },
  computed: {
    computedDisabled() {
      if (!this.shiftInfo.dentistry.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.role = localStorage.getItem("role");

    this.getShiftInfo();
  },
};
</script>
<style lang="scss">
.resultBox {
  padding: 10px;
}

@media print {
  .dontPrint {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .v-tabs--vertical {
    display: flex !important;
    flex-direction: column !important;
  }
  .v-tab {
    border-bottom: 1px solid #ccc;
  }
  .v-window {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 600px) {
  .v-window {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
.v-tabs-slider {
  display: none;
}
</style>
